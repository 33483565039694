import React from 'react';
import './ProductPageIntro.css';

const ProductIntro = () => {
  return <div className='ProductIntro'>
      <div className='Products'>
<h1  data-aos="zoom-in" data-aos-duration="2000">SPEAKERS</h1>
  </div>;
  </div>
};

export default ProductIntro;
